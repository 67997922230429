import bodyScrollLockUpgrade from './body-scroll-lock-upgrade.js'; // Import body scroll lock upgrade module

const btnOpen = document.querySelector('#btnOpen'); // Get open button element
const btnClose = document.querySelector('#btnClose'); // Get close button element
const media = window.matchMedia('(max-width: 48em)'); // Media query for max-width of 48em (768px)
const mainNavMenu = document.querySelector('.mainnav__menu'); // Get main navigation menu element
const main = document.querySelector('main'); // Get main element
const body = document.querySelector('body'); // Get body element

// Function to open mobile menu
function openMobileMenu() {
  btnOpen.setAttribute('aria-expanded', 'true'); // Set aria-expanded attribute to true
  mainNavMenu.removeAttribute('inert'); // Remove inert attribute from main navigation menu
  mainNavMenu.removeAttribute('style'); // Remove inline styles from main navigation menu
  main.setAttribute('inert', ''); // Set inert attribute on main element
  bodyScrollLockUpgrade.disableBodyScroll(body); // Disable body scroll
  btnClose.focus(); // Focus on close button
}

// Function to close mobile menu
function closeMobileMenu() {
  btnOpen.setAttribute('aria-expanded', 'false'); // Set aria-expanded attribute to false
  mainNavMenu.setAttribute('inert', ''); // Set inert attribute on main navigation menu
  main.removeAttribute('inert'); // Remove inert attribute from main element
  btnOpen.focus(); // Focus on open button

  // Remove transition after closing menu
  setTimeout(() => {
    mainNavMenu.style.transition = 'none';
  }, 500);
}

// Function to set up top navigation based on media query
function setupTopNav(e) {
  e.matches
    ? (mainNavMenu.setAttribute('inert', ''), mainNavMenu.style.transition = 'none') // If matches media query, set inert attribute and remove transition
    : (mainNavMenu.removeAttribute('inert'), mainNavMenu.style.transition = '', mainNavMenu.style.transitionProperty = 'transform'); // Otherwise, remove inert attribute and apply transition
}

// Initial setup based on media query
setupTopNav(media);

// Event listeners for open and close buttons
btnOpen.addEventListener('click', openMobileMenu);
btnClose.addEventListener('click', closeMobileMenu);

// Event listener for media query change
media.addEventListener('change', function (e) {
  setupTopNav(e); // Call setupTopNav function on media query change
});

import renderWorkLogs from '../renders/worklogRenders';
import ApiService from '../services/ApiService';
import localStorageUtil from '../utils/localStorage';
import { formatEditLogDateTime } from '../utils/timeFormat';
import renderErrors from '../renders/errorRenders';


const apiService = new ApiService();
const workLogForm = document.getElementById('workLogForm');

//Varibales to store DOM-Elements
let workLogs;
let workLogToEdit;
let submitButton;
let rowToEdit;
let editRowDiv;

//Gets worklogs from database and calls render function
export async function getWorkLogs(){
    try {
      workLogs = await apiService.getLogs();
      await renderWorkLogs(workLogs);
  
    } catch (error) {
      console.error('Fel vid hämtande av work logs:', error.message);
    }
  }
  
//Start edit worklog
export function startWorkLogEdit(parentID){

  if(workLogToEdit){endWorkLogEdit();} // If edit already is active, end current edit before continue

  //Find worklog
  workLogToEdit = workLogs.find(log => log.id === parentID);
  localStorageUtil.setItem('workLogToEdit', workLogToEdit);

  //Get elements from DOM
  const startTimeInput = document.getElementById('startTime');
  const endTimeInput = document.getElementById('endTime');
  submitButton = document.getElementById('submitWorkLogForm');
  editRowDiv = document.getElementById('editRow');

 //Format to dateTime
 const dateTime = formatEditLogDateTime(workLogToEdit.start_time, workLogToEdit.end_time)
 
 //Set value to formatted time
 startTimeInput.value = dateTime.formattedStartDate;
 endTimeInput.value = dateTime.formattedEndDate;

 //Add cancel button
 editRowDiv.innerHTML += '<button class="btn__primary--cancel" id="cancelEdit">Avbryt ändring</button>';
 const cancelEditFormButton = document.getElementById('cancelEdit');
 
 //Get row to edit element
 rowToEdit = document.getElementById(`${workLogToEdit.id}`);
 rowToEdit.classList.add('rowToEdit');

 //Change text on submitButton
 submitButton.innerHTML = "Ändra";

 //Add eventlistener for cancel button
 cancelEditFormButton.addEventListener('click',endWorkLogEdit);

}

//End work log edit
function endWorkLogEdit(){
  submitButton.innerHTML = "Lägg Till"; //Change submit-button text
  rowToEdit.classList.remove('rowToEdit'); //Remove hightlight of row
  editRowDiv.innerHTML = ""; //Clear div
  workLogForm.reset(); //Reset form
  localStorageUtil.removeItem('workLogToEdit'); //Remove from local storage
  
} 

//On forms submit
export async function workLogFormSubmit(e){
  e.preventDefault(); //Prevent form submisson

  //Create formdata object
  const formdata = new FormData(workLogForm);

  //Get startTime and endTime
  const startTime = formdata.get('startTime');
  const endTime = formdata.get('endTime');

  //Get workLogToEdit from localStorage
  const workLogToEdit = localStorageUtil.getItem('workLogToEdit');

  try {
    //If workLogtoEdit
    if (workLogToEdit){
      try {
        await apiService.editWorkLogEntry(startTime, endTime, workLogToEdit.id); //Sends edit request to databse
        endWorkLogEdit(); //Stops current edit
        getWorkLogs(); //Calls function to render worklogs in table
        localStorageUtil.removeItem('workLogToEdit'); //Removes worklog in localstorage

      } catch (error) {
        console.error(error);
      }

    }else{
      const workLogEntry = await apiService.createWorkLogEntry(startTime, endTime);
      if (workLogEntry.errors) {
        renderErrors(workLogEntry); // Calls renderErrors function from renders/errorRenders.js
        return;
      }
      workLogForm.reset();
      getWorkLogs();
    }

    
} catch (error) {
    console.error('Misslyckades lägga till worklog:', error);
}
}

// Utils
import './utils/navigation/navigation.js'; // Import navigation utility module
import localStorageUtil from './utils/localStorage.js'; // Import localStorage utility module

import AuthService from './services/AuthService.js'; // Import AuthService class
const authService = new AuthService(); // Create an instance of AuthService

// Controllers
import { loginFormSubmit, registerFormSubmit } from './controllers/authController.js'; // Import login and register form submit functions from authController
import { getWorkLogs, workLogFormSubmit } from './controllers/workLogControllers'; // Import functions for managing work logs from workLogControllers
import ApiService from './services/ApiService.js'; // Import ApiService class

// Get DOM elements
const loginForm = document.getElementById('loginForm'); // Get login form element
const workLogForm = document.getElementById('workLogForm'); // Get work log form element
const workLogEl = document.getElementById('workLog'); // Get work log element
const registerForm = document.getElementById('registerForm'); // Get register form element
const mainNavEl = document.getElementById('mainNav'); // Get main navigation element

// Event listeners
loginForm?.addEventListener('submit', loginFormSubmit); // Add event listener for login form submission
registerForm?.addEventListener('submit', registerFormSubmit); // Add event listener for register form submission
workLogForm?.addEventListener('submit', workLogFormSubmit); // Add event listener for work log form submission

// Wait for DOM content to be loaded
document.addEventListener('DOMContentLoaded', () => {
    const currentURL = window.location.href;

    // Check if user is not logged in
    if (!localStorageUtil.getItem('token')) {
        // Display login link in main navigation if user is not logged in
        mainNavEl.innerHTML += '<li class="mainnav__item"><a href="login.html" class="mainnav__link">Logga in</a></li>';

        // Redirect to login page if current URL contains "/worklog"
        if (currentURL.includes("/worklog")) {
            window.location.href = '/login';
        }
    } else if (localStorageUtil.getItem('token')) {
        // Display Worklog and Logout links in main navigation if user is logged in
        mainNavEl.innerHTML += '<li class="mainnav__item"><a href="worklog.html" class="mainnav__link">Worklog</a></li>';
        mainNavEl.innerHTML += '<li class="mainnav__item"><a href="logout.html" class="mainnav__link">Logga ut</a></li>';

        // Fetch and display work logs if work log element exists
        if (workLogEl) {
            getWorkLogs();
        }
    }

    // Logout user if current URL contains '/logout'
    if (currentURL.includes('/logout')) {
        authService.logout();
    }

    // Remove 'workLogToEdit' item from localStorage if it exists
    if (localStorageUtil.getItem('workLogtoEdit')) {
        const workLogToEdit = localStorageUtil.getItem('workLogToEdit');
        if (workLogToEdit) {
            localStorageUtil.removeItem('workLogToEdit');
        }
    }
});

import formatLogTime from '../utils/timeFormat';
import getParentID from '../utils/getParentId';
import ApiService from '../services/ApiService';
import {formatLogTime} from '../utils/timeFormat';
import { getWorkLogs, startWorkLogEdit } from '../controllers/workLogControllers';;

const apiService = new ApiService();

const workLogEl = document.getElementById('workLog');


export default async function renderWorkLogs(workLogs){
    try {  
      //Empty worklog innerhtml
      workLogEl.innerHTML = "";

      //Empty errorEl
      const errorEl = document.getElementById('error'); 
      errorEl.innerHTML = ""; //Clear error-element


      //Sort array, latest enddate first
      workLogs.sort((a, b) => new Date(b.end_time) - new Date(a.end_time));
      
      //Loop through worklogs
      workLogs.forEach(log => {
        const { startDate, startTime, endDate, endTime, totalWorkHours } = formatLogTime(log);
        
        //render worklogs
        workLogEl.innerHTML += `
          <tr id="${log.id}">
            <td>${startDate} <br /> <strong>${startTime}</strong></td>
            <td>${endDate} <br /> <strong>${endTime}</strong></td>
            <td><strong>${totalWorkHours}</strong></td>
            <td><button class="btn__icon--edit"><i class="ri-edit-2-fill"></i></button><button class="btn__icon--delete"><i class="ri-delete-bin-2-line"></i></button></td>
          </tr>
        `;
    
      });
  
         //Add eventlisteners to delete-buttons
         const deleteButtons = document.querySelectorAll('.btn__icon--delete');
         deleteButtons.forEach(item => {
             item.addEventListener('click', async (e) => {
                const target = e.target;
                const parentID = getParentID(target);
                let confirm = window.confirm('Är du säker på att du vill ta bort loggen?'); //Confirm dialog
                  //If yes
                if (confirm){
                  const deleteWorkLog = await apiService.deleteWorkLogEntry(parentID);
                  if (deleteWorkLog === 204){
                    getWorkLogs();
                  }
                }
             });
         });

            //Add eventlisteners to edit-buttons
            const editButtons = document.querySelectorAll('.btn__icon--edit');
            editButtons.forEach(item => {
                item.addEventListener('click', async (e) => {
                    const target = e.target;
                    const parentID = getParentID(target);
                    startWorkLogEdit(parentID);
                });
            });
  
    } catch (error) {
      console.error('Fel vid hämtande av work logs:', error.message);
    }
  }
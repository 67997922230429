// localStorage.js
const localStorageUtil = {
    //Saves item in localstorage
    setItem(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    //Get item in localstorage
    getItem(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },
    //Removes item in localstorage
    removeItem(key) {
        localStorage.removeItem(key);
    }
};

export default localStorageUtil;

import AuthService from '../services/AuthService.js';
import userFormDataExtract from '../utils/forms/userFormDataExtract';

import renderErrors  from '../renders/errorRenders.js';

const authService = new AuthService();

export async function loginFormSubmit(e) {
    e.preventDefault(); // Prevent form submission

    try {
        // Get userdata from form
        const userData = await userFormDataExtract(loginForm);

        // Sign in
        const user = await authService.login(userData.username, userData.password);

        // If errors
        if (user.errors) {
            renderErrors(user); // Calls renderErrors function from renders/errorRenders.js
            return;
        }

        // Redirect to worklog
        window.location.href = '/worklog';

    } catch (error) {
        console.error('Fel vid inloggning:', error);
    }
}


export async function registerFormSubmit(e) {
    e.preventDefault(); // Prevent form submission

    try {
        // Get userdata from form
        const userData = await userFormDataExtract(registerForm);

        // Register user
        const user = await authService.register(userData.username, userData.password);

        // If errors
        if (user.errors) {
            renderErrors(user); // Calls renderErrors function from renders/errorRenders.js
            return;
        }

       //redirect to login
        window.location.href = '/login';

    } catch (error) {
        console.error('Fel vid inloggning:', error);
    }
}
import localStorageUtil from '../utils/localStorage.js'; // Import localStorage utility module
import config from '../utils/config'; // Import configuration module

class AuthService {
    // Method to log in the user
    async login(username, password) {
        try {
            // Send POST request to login endpoint with username and password
            const response = await fetch(`${config.base_url}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            });

            // Parse response data as JSON
            const data = await response.json();

            // Return data if response is not OK
            if (!response.ok){
                return data;
            }

            // Store JWT-token in localStorage if login is successful
            localStorageUtil.setItem('token', data.response.token);
            
            return data.response; // Return response if login is successful
        } catch (error) {
            throw new Error('Login failed: ', error.message); // Throw error if login fails
        }
    }

    // Method to register a new user
    async register(username, password) {
        try {
            // Send POST request to register endpoint with username and password
            const response = await fetch(`${config.base_url}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            });

            // Parse response data as JSON
            const data = await response.json();

            // Return data if response is not OK
            if (!response.ok) {
                return data;
            }

            return data; // Return data
        } catch (error) {
            return error; // Return error if registration fails
        }
    }

    // Method to log out the user
    logout() {
        const countdownElement = document.getElementById('countdown'); // Get countdown element
        let countdown = 3; // Initial countdown value
        localStorageUtil.removeItem('token'); //Remove token = logout
        
        // Function to update the countdown
        function updateCountdown() {
            countdownElement.innerHTML = `Du omdirigeras till startsidan om ${countdown} sekunder.`; // Update countdown text
    
            if (countdown === 0) {
                window.location.href = '/index'; // Redirect to homepage when countdown is done
            } else {
                countdown--; // Decrease countdown
                setTimeout(updateCountdown, 1000); // Update countdown every second
            }
        }
    
        // Start the countdown
        updateCountdown();
    }
}

export default AuthService; // Export AuthService class

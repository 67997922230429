export function formatLogTime(log) {
  // Convert start_time to local date and time
  const start = new Date(log.start_time);
  const startDate = start.toLocaleDateString('sv-SE');
  const startTime = start.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' });
  
  // Convert end_time to local date and time
  const end = new Date(log.end_time);
  const endDate = end.toLocaleDateString('sv-SE');
  const endTime = end.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' });

  // Return formatted date and time along with total work hours
  return {
    startDate,
    startTime,
    endDate,
    endTime,
    totalWorkHours: log.work_hours
  };
}

export function formatEditLogDateTime(startTime, endTime){
  // Convert start time to local date and time
  const startTimeUTC = new Date(startTime);
  const startTimeLocal = new Date(startTimeUTC.getTime() - (startTimeUTC.getTimezoneOffset() * 60000));
  const formattedStartDate = startTimeLocal.toISOString().slice(0, 16); 

  // Convert end time to local date and time
  const endTimeUTC = new Date(endTime);
  const endTimeLocal = new Date(endTimeUTC.getTime() - (endTimeUTC.getTimezoneOffset() * 60000));
  const formattedEndDate = endTimeLocal.toISOString().slice(0, 16);

  // Return formatted start and end date-time
  return {formattedStartDate, formattedEndDate};
}

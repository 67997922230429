//Get error element from dom
const errorEl = document.getElementById('error'); 

export default function renderErrors(user){
    errorEl.innerHTML = ""; //Clear error-element
    
    //Loop through errors
    if(Array.isArray(user.errors)){
        user.errors.forEach(error => {
            console.error(error.msg);
            errorEl.innerHTML += `<li>${error.msg}</li>`;
        });
        } else {
        errorEl.innerHTML += `<li>${user.errors}</li>`;
        }
}    

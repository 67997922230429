import localStorageUtil from '../utils/localStorage.js'; // Importing localStorage utility module
import config from '../utils/config'; // Importing configuration module

class ApiService {
    
    // Method to fetch work logs from the API
    async getLogs(){
        try {
            const token = localStorageUtil.getItem('token'); // Get token from localStorage

            // Throw error if token is not available
            if (!token){
                throw new Error('Token not found');
            }
            
            // Fetch work logs from API
            const response = await fetch(`${config.api_url}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add token to headers
                    'Content-Type': 'application/json'
                }
            });

            // Parse response data as JSON
            const data = await response.json();

            // Throw error if response is not OK
            if (!response.ok) {
                throw new Error('Failed to fetch work logs: ', data);
            }

            return data; // Return fetched data
        } catch (error) {
            throw new Error('Failed to fetch work logs: ', error); // Throw error if fetching logs fails
        }
    }

    // Method to create a new work log entry
    async createWorkLogEntry(startTime, endTime){
        try {
            const token = localStorageUtil.getItem('token'); // Get token from localStorage

            // Throw error if token is not available
            if (!token){
                throw new Error('Token not found');
            }

            // Create request data with start time and end time
            const requestData = {
                startTime: startTime,
                endTime: endTime
            };

            // Send POST request to create work log entry
            const response = await fetch(`${config.api_url}/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add token to headers
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData) // Send start time and end time with body
            });

            // Parse response data as JSON
            const data = await response.json();

            // Return data if response is OK
            if (!response.ok) {
                return data;
            }
          
            return data; // Return data
        } catch (error) {
            throw new Error('Failed to fetch work logs: ', error); // Throw error if creating work log fails
        }
    }
      
    // Method to delete a work log entry
    async deleteWorkLogEntry(id){
        try {
            const token = localStorageUtil.getItem('token'); // Get token from localStorage

            // Throw error if token is not available
            if (!token) {
                throw new Error('Token not found');
            }

            // Throw error if ID is not provided
            if (!id) {
                throw new Error('ID must be provided');
            }

            // Send DELETE request to delete work log entry
            const response = await fetch(`${config.api_url}/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });


            // Check if the server response indicates successful deletion
            if (response.ok) {
                return response.status; // Return data from server if successful
            } else {
                // If something went wrong on the server, throw an error with the server's error message
                throw new Error(`Failed to delete work log: ${data.error}`);
            }
        } catch (error) {
            throw new Error('Failed to delete work log: ' + error.message); // Throw error if deleting work log fails
        }
    }

    // Method to edit a work log entry
    async editWorkLogEntry(startTime, endTime, id){
        try {
            const token = localStorageUtil.getItem('token'); // Get token from localStorage

            // Throw error if token is not available
            if (!token){
                throw new Error('Token not found');
            }

            // Throw error if ID is not provided
            if (!id) {
                throw new Error('ID must be provided');
            }

            // Create request data with start time and end time
            const requestData = {
                startTime: startTime,
                endTime: endTime
            };

            // Send PUT request to edit work log entry
            const response = await fetch(`${config.api_url}/edit/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add token to headers
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData) // Send start time and end time with body
            });
            
            // Parse response data as JSON
            const data = await response.json();

            // Return data if response is OK
            if (!response.ok) {
                return data;
            }
          
            return data; // Return data
        } catch (error) {
            throw new Error('Failed to update work log: ', error); // Throw error if updating work log fails
        }
    }
}

export default ApiService; // Export ApiService class
